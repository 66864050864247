@font-face {
    font-family: "Stem";
    src:  url("assets/STM35.woff2") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Stem";
    src:  url("assets/STM55.woff2") format("woff");
    font-weight: bold;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

.wf-active body, .wf-active button, .wf-active input {
    font-family: "Stem", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
}

:root {
    --white-color: rgb(250, 250, 250);
    --text-color: rgb(28, 28, 28);
    --green-color: rgb(135, 207, 253);
    --dark-green-color: rgba(135, 207, 253, .5);
    --grey-color: rgb(210, 215, 220);
    --dark-color: rgb(120, 122, 125);
}

body {
    margin: 0 auto;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--white-color);
    color: var(--text-color);
    font-size: 1em;
    line-height: 1.49678em;
    height: 100vh;
}

#root {
    width: 100%;
    height: inherit;
}

.lds-dual-ring {
    display: inline-block;
    width: 100%;
    height: 100%;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 1rem;
    height: 1rem;
    margin: .125rem;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
