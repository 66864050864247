.main {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
}

.main__description {
    width: 100%;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__description h3 {
    text-align: center;
    font-weight: bold;
}

.main__description p {
    font-weight: normal;
    text-align: center;
}

.main__logo--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main__logo--wrapper div > p {
    margin: 0;
    line-height: 1.196;
    font-weight: bold;
    font-size: 2rem;
}

.main__logo--wrapper div {
    margin-left: 1rem;
}

.main__logo {
    width: 35rem;
    max-width: 100%;
    height: auto;
}

.main__form {
    border-radius: 1rem;
    width: 100%;
    margin: 1rem auto 0;
    background: #F7F7F7;
    color: var(--text-color);
    box-shadow: 6px 6px 12px 0 rgba(28, 28, 28, 0.2);
}

.main__form__background {
    background-size: cover !important;
    background: url("./assets/mobile.png") no-repeat center;
    width: 100%;
    height: 15.75rem;
    border-radius: 1rem 1rem 0 0;
}

.form {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.form__title {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    text-align: center;
}

.form__description {
    text-align: center;
}

.form__inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-top: 2rem;
}

.form__inputs--center {
    margin: 2rem auto 0;
    width: 100%;
    max-width: 15rem;
}

.form__input {
    display: block;
    position: relative;
}

.form__input label {
    position: absolute;
    cursor: text;
    font-size: 80%;
    opacity: 1;
    top: -.4rem;
    left: .75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    display: inline-block;
    margin-bottom: .5rem;
}

.form__input label:after {
    content: " ";
    display: block;
    position: absolute;
    height: 5px;
    top: 3px;
    left: -.2em;
    right: -.2em;
    z-index: -1;
    background: white !important;
}

.form__input input {
    border-radius: .1rem;
    outline: medium none invert !important;
    outline: initial !important;
    box-shadow: none !important;
    font-size: 1em;
    border: 1px solid var(--grey-color);
    background: white;
    color: var(--text-color);
    height: auto;
    padding: .5rem .75rem;
    line-height: 1;
    display: block;
    width: 100%;
    font-weight: 300;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin: 0;
}

.form__input .error {
    background: #fff0f4;
    color: rgb(197, 18, 68);
    border: 1px solid rgb(197, 18, 68) !important;
}

.form__input .success {
    background: rgb(238, 255, 239);
    color: rgb(64, 129, 64);
    border: 1px solid rgb(83, 166, 83) !important;
}

.form__input--radios {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.form__radio {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: .875em;
}

.form__radio:not(:last-child) {
    margin-right: 1rem;
}

.form__checkbox {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.form__checkbox label {
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 200px;
}

.form__radio label {
    margin-top: -3px;
    cursor: pointer;
}

[type="radio"]:checked,
[type="radio"]:not(:checked),
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    display: inline-block;
    color: var(--text-color);
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--grey-color);
    border-radius: 100%;
    background: white;
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border: 1px solid var(--grey-color);
    background: white;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--green-color);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--green-color);
    position: absolute;
    top: 19px;
    left: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after,
[type="checkbox"]:not(:checked) + label:after{
    opacity: 0;
    transform: scale(0);
}

[type="radio"]:checked + label:after,
[type="checkbox"]:checked + label:after{
    opacity: 1;
    transform: scale(1);
}

.form__input--button {
    display: flex;
    align-items: center;
}

.form__button {
    border: none;
    font-size: 1em;
    cursor: pointer;
    background: var(--green-color);
    color: black;
    padding: .5rem .75rem;
    border-radius: 1rem;
    margin: 0 auto;
    transition: background-color .2s ease-in;
    font-weight: bold;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.form__button:hover {
    background: var(--dark-green-color);
}

.form__button:disabled {
    color: var(--dark-color);
    background-color: var(--grey-color);
    cursor: not-allowed;
}

.form__button:disabled:hover {
    color: var(--dark-color);
}

.form__submit {
    align-self: center;
    margin-top: 2rem;
    background: var(--green-color);
    border-radius: 1rem;
    outline: none;
    border: 1px solid transparent;
    color: black;
    font-size: 1em;
    cursor: pointer;
    padding: .75rem 2.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__submit:hover {
    background: var(--dark-green-color);
}

.form__submit:disabled {
    background: var(--dark-color);
    cursor: not-allowed;
}

.form__submit:disabled:hover {
    background: var(--dark-green-color);
}

.form__timer {
    padding: 0;
    margin: 0 1rem 0 0;
}

.form__timer--hidden {
    display: none;
}

.form__notification {
    text-align: center;
    font-size: .875em;
}

.form__notification--error {
    color: rgb(197, 18, 68);
}

.form__cards {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form__cards__apple {
    height: 3rem;
}

.form__card-number {
    text-align: center;
}

.form__card-number strong {
    color: black;
}

.form__after-signup {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: 999;
}

.form__after-signup--block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form__disclaimer {
    display: flex;
    text-align: center;
    font-size: .75em;
    margin: 3em auto 0;
}

.form__disclaimer a {
    color: var(--dark-green-color);
    text-decoration: none;
}

.form__disclaimer a:hover {
    text-decoration: underline;
}

.main__list {
    font-size: .875em;
}

@media (min-width: 44rem) {

    .main__form__background {
        background: url("./assets/background.png") no-repeat center;
    }

    .form__checkbox label {
        width: 100%;
    }


    .main {
        padding: 5rem 0;
    }

    .form {
        padding: 2rem;
    }

    .form__inputs {
        grid-template-columns: 1fr 1fr;
    }

    .form__inputs--center {
        grid-template-columns: 1fr;
    }

    .form__radio {
        font-size: 1em;
    }

    .form__radio:not(:last-child) {
        margin-right: 1.5rem;
    }


    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after {
        top: 7px;
    }

}

.form__inputs--hidden {
    display: none;
}